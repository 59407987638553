.authentication {
    width: 100%;
    height: 100vh;
    display: flex;
}

.form-box {
    width: 553px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.form-box .auth-head h3 {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 12px;
}

.form-box .auth-head p {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #575A74;
}

.form-box .auth-form {
    margin-top: 40px;
}

.form-box .auth-form label {
    margin-bottom: 8px;
}

.auth-form a {
    color: #ffffff;
    text-decoration: none;
}

.auth-form .form-group {
    margin-bottom: 24px;
}

.auth-form .form-group label {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
    margin: 0;
}

.auth-form .form-group ::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}

.auth-form .forget-link {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    margin-top: 12px;
}

.auth-form .form-submit {
    background: #2281E3;
    border: none;
    width: 100%;
    border-radius: 8px;
    height: 51px;
    margin-top: 24px;
    color: white;
    font-size: 16px;
    font-weight: 700;
}

.auth-form .form-link {
    letter-spacing: 0em;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    color: #FFFFFF;
    text-align: center;
    margin-top: 32px;
}

.auth-form .form-link a {
    color: #2281E3;
}

.auth-form .form-group .auth-select {
    width: 100%;
    height: 49px;
    border: 1px solid #ffffff21;
    border-radius: 8px;
    color: #fff;
    background: var(--theme-bg);
    font-size: 14px;
    padding: 0px 14px;
    outline: none;
}

.authentication .form-group input[type="text"], .authentication .form-group input[type="password"] {
    width: 100%;
    height: 49px;
    padding-left: 16px;
    background: #010226;
    border: 1px solid #ffffff21;
    outline: none;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
}

.right-box {
    width: 40%;
    position: relative;
}

.left-box {
    width: 60%;
    background-color: var(--theme-bg);
    padding: 20px;
    position: relative;
}

.right-box img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ads-platform-type {
    border: none !important;
    background: #F7F7F7;
}