.w-150 {
    width: 150px;
}

.w-120 {
    width: 120px;
}

.profiles {
    width: 100%;
    height: 100%;
    background: white;
    padding: 10px;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    margin: auto;
    position: relative;
}

.profiles:hover {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .2);
    transition: all .2s;
}

.profile-status {
    width: 40px;
    height: 40px;
    background: #010226;
    border-radius: 50px;
    position: absolute;
    top: 5px;
    right: 5px;
}

.profile-status svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.profile-img {
    display: block;
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;
    margin: auto;
    text-decoration: none;
    margin-right: 20px;
}

.profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.employee-profile-text {
    width: 100%;
    height: 100%;
    line-height: 60px;
    background: #010226;
    text-align: center;
    font-size: 26px;
    color: #fff;
    text-transform: capitalize;
}
.employee-profile-text.lh {
    line-height: 40px;
}

.profile-name {    
    font-size: 18px;
    font-weight: 600;
    color: #010226;
    display: block;
    text-decoration: none;
    margin-top: 10px;
    text-transform: capitalize;
}

.profile-role {    
    font-size: 14px;
    font-weight: 500;
    color: #575a74;
    text-transform: capitalize;
}

.action-menu {
    position: absolute;
    top: 9px;
    right: 3px;
    cursor: pointer;
}

.profile-view {
    padding: 10px 10px;
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    display: flex;
    gap: 50px;    
    margin-bottom: 5px;
}

.profile-view-image {
    width: 10%;
    height: 144px;
    border-radius: 50%;
    overflow: hidden;
    /* border: 1px solid #010226; */
}

.profile-view-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-view-image .employee-profile-text {
    line-height: 144px;
    font-size: 45px;
}

.profile-view-info {
    width: 100%;
    color: #010226;
    position: relative;
}

.profile-right {
    color: #010226;
    border-left: 2px dashed #010226;
    padding-left: 10px;
}

.profile-view-info .profile-title {
    width: 130px;
    padding: 8px;
}

.profile-view-info .action-profile {
    position: absolute;
    top: 0;
    right: 0;
}

.action-profile .btn-profile {
    padding: 8px;
    border-radius: 50%;
    border: none;
    outline: none;
    color: #f8f8f8;
    transition: all .3s;
}

.action-profile .btn-profile.theme-primary:hover{
    background-color: #0169d5;
}

.action-profile .btn-profile.theme-danger:hover {
    background-color: #db4326;
}

.action-profile .btn-profile.theme-success:hover {
    background-color: #11761e;
}

.profile-view-info .action-profile {
    position: absolute;
    top: 0;
    right: 0;
    display: grid;
    row-gap: 5px;
}

.leave-status {
    width: fit-content;
    padding: 5px 10px;
    border: 1px solid #a7a7a7;
    border-radius: 50px;
    margin: auto;
}

.profile-setting .content-header-right {
    padding: 13px 0px;
    display: flex;
    justify-content: center;
}

.search-date select {
    padding: 5px 9px;
    border: 1px solid #e1e1e1;
    border-radius: 10px;
    color: #757575;
    outline: none;
}

.employee-task:hover {
    text-decoration: underline;
    text-decoration-color: #010226;
    cursor: pointer;
}

/* ---- Employee added model */

.employee-model .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0 24px;
}

.employee-information .employee-upload-profile {
    width: 120px;
    height: 120px;
    border: none;
    border-radius: 8px;
    position: relative;
    margin: auto;
    margin-bottom: 24px;
    overflow: hidden;
}

.employee-upload-profile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.employee-upload-profile .image-upload-btn {
    width: 100%;
    height: 28px;
    background: #4a4a4ab3;
    position: absolute;
    bottom: 0;
    cursor: pointer;
}

.image-upload-btn label {
    color: #fff;
    font-weight: 400;
    text-align: center;
    display: block;
    margin: 0;
    cursor: pointer;
    line-height: 28px;
}
.switch {
    display: inline-block;
    height: 18px;
    position: relative;
    width: 36px;
}
.switch input {
    height: 0;
    opacity: 0;
    width: 0;
}
input:checked+.slider {
    background: linear-gradient(66deg, #6c5dd3 15.4%, #af56d9 84.6%);
}

.slider.round {
    border-radius: 20px;
}

.slider {
    background-color: #ccc;
    cursor: pointer;
    left: 0;
    right: 0;
    transition: .4s;
}
input:checked+.slider:before {
    transform: translateX(16px);
}

.slider.round:before {
    border-radius: 50%;
}

.slider:before {
    background-color: #fff;
    content: "";
    height: 12px;
    left: 4px;
    margin: auto;
    width: 12px;
}

.slider,
.slider:before {
    bottom: 0;
    position: absolute;
    top: 0;
}
input:focus+.slider {
    box-shadow: 0 0 1px #6c5dd3;    
}

.slider.round {
    border-radius: 20px;
}


/* ------------------------- */
.password-input-container {
    margin-bottom: 16px;
}

.input-group {
    position: relative;
}

.password-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.toggle-password-btn {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
}