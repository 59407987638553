.rmdp-shadow {
    box-shadow: unset;
    margin: auto;
}

.settings {
    padding: 0px 25px 25px 0px;
}
.iconWrap svg{
        display: block;
        height: 20px;
        width: 20px;
    }
button.iconWrap{
    border: 0;
}
button.iconWrap:hover {
    color:#2281e3;
}