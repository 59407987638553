.imageWrap{
    width: 200px;
    height:200px;
    position: relative;
}
.imageWrap img{
    position: absolute;
    height: 100%;
    width: 100%;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}