@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700&display=swap');

@font-face {
  font-family: Figtree;
  src: url(../public/Fontas/Figtree-Regular.ttf), url(../public/Fontas/Figtree-Bold.ttf), url(../public/Fontas/Figtree-Medium.ttf), url(../public/Fontas/Figtree-SemiBold.ttf);
}

* {
  margin: 0px;
  padding: 0px;
  font-family: 'Figtree', sans-serif;
}
a{
  text-decoration: none;
}
:root {
  --theme-bg: #010226;
  --theme-text: #010226;
  --theme-primary: #2281E3;
  --theme-white: #ffffff;
  --theme-content-bg: #fdfdfd;
  --theme-danger: #FF5636;
}

p {
  margin: 0%;
}

ul {
  margin: 0;
  list-style: none;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-24 {
  margin-top: 24px;
}

.m-24 {
  margin: 24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.p-16 {
  padding: 16px;
}

.pt-24 {
  padding-top: 24px;
}

.theme-text {
  color: #010226;
}

.theme-bg {
  background-color: #010226;
}

.theme-primary {
  background-color: #2281E3;
}

.theme-success {
  background-color: #359742;
}

.theme-danger {
  background-color: #FF5636;
}

.theme-warning {
  background-color: #E5B700;
}

.theme-text-danager {
  color: #FF5636 !important;
}

.theme-text-white {
  color: #ffffff;
}

.theme-text-success {
  color: #359742;
}

.theme-text-primary {
  color: var(--theme-primary);
}

.theme-btn {
  padding: 12px;
  border: 0;
  border-radius: 8px;
}

.theme-btn-primary {
  padding: 12px;
  border: 0;
  border-radius: 8px;
  background-color: var(--theme-primary);
  color: var(--theme-white);
  transition: all .3s;
}
.theme-btn-primary:disabled{
opacity: 0.2;
cursor: no-drop;
}
.theme-btn-primary.btn-success {
  background-color: #198754;
}
.theme-btn-primary.btn-danger {
  background-color: #bb2d3b;
}
.theme-btn-primary.btn-dark {
  background-color: #000;
}
.theme-btn-primary.btn-warning {
  background-color: #ffca2c;
}
.theme-btn-primary:hover {
  background-color: #0169d5;
}
.btnwdth{
  min-width: 90px;
  white-space: nowrap;
}
.theme-success-sm {
  padding: 4px 18px;
  border: 0;
  border-radius: 7px;
  background-color: #0EB17F;
}

.theme-danger-sm {
  padding: 4px 18px;
  border: 0;
  border-radius: 7px;
  background-color: #D2543C;
}

.theme-primary-sm {
  padding: 4px 18px;
  border: 0;
  border-radius: 7px;
  background-color: #2281e4;
}

.breadcrumb {
  margin: 0;
}

.breadcrumb .active {
  color: var(--theme-primary);
  padding-left: 3px;
}

.breadcrumb .back-link {
  color: var(--theme-text);
  text-decoration: underline;
  padding-right: 3px;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: #fff;
  background: #0EB17F;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: #fff;
  background: #D2543C;
}

.preloader {
  width: 100%;
  height: 50%;
  position: relative;
}

/* Commen CSS */

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f8f8f8;
}

.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
  border: 1px solid var(--theme-bg);
}

.rmdp-container .custom-calendar.ep-arrow[direction="bottom"] {
  border-top: 1px solid var(--theme-bg);
}

.rmdp-ep-arrow[direction=top] {
  border-bottom: unset;
}

.rmdp-ep-shadow:after {
  box-shadow: unset;
}

.lds-ellipsis {
  position: absolute;
  top: 38%;
  left: 50%;
}

.lds-ellipsis div {
  position: absolute;
  top: 0px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.table-status-badge {
  padding: 3px 22px;
  border-radius: 50px;
  font-size: 16px;
}
.usercolorstoke svg path{
  stroke: #565973;
}
.table-list {
  width: 100%;
}

.table-list tr td {
  padding: 10px 10px;
  color: var(--theme-text);
  font-size: 16px;
  word-break: break-word;
}

.table-list tr th {
  padding: 10px 10px;
  color: #575A74;
  font-size: 16px;
  font-weight: 400;
}

.table-list tr {
  height: 60px;
  border-bottom: 1px solid #1111111A;
}

.table-list .list_id {
  width: 50px;
  text-align: center;
}
.dark-toast {
background:#fff;  
border-left: 2px solid red;  
box-shadow: 0px 0px 2px 0px #0000001F,0px 4px 8px 0px #00000024;
}
label {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--theme-text);
  margin: 0;
  padding-bottom: 8px;
}
.marginright{
  margin-right: 15px;
}
.form-group {
  margin: 24px 0px;
}

.form-group label {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--theme-text);
  margin: 0;
  padding-bottom: 8px;
}

.form-group textarea {
  width: 100%;
  border: 1px solid #1111111A;
  border-radius: 8px;
  color: var(--theme-text);
  padding: 16px;
}

.form-group textarea:focus-visible {
  outline: unset;
}

.form-group input[type="text"],
.form-group input[type="password"],
.form-group input[type="date"],
.form-group input[type="time"],
.form-group input[type="datetime-local"] {
  width: 100%;
  height: 50px;
  padding-left: 16px;
  background: #FFFFFF;
  border: 1px solid #1111111A;
  outline: none;
  border-radius: 8px;
  color: var(--theme-text);
  font-size: 14px;
  padding-right: 16px;
}
.form-group input[type="time"]{
  width: auto;
}
.form-group ::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #575A74;
}

.form-group .button-app {
  margin-bottom: 5px;
  float: right;
}

.form-group .button {
  position: relative;
  width: 40px;
  height: 24px;
  overflow: hidden;
}

.form-group .button .checkbox:checked+.knobs:before {
  content: "";
  left: 18px;
  background-color: #198754;
}

.form-group .button .knobs:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 4px;
  width: 18px;
  height: 18px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: #dc3545;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.button-app {
  float: right;
}

.button-app .button {
  position: relative;
  width: 40px;
  height: 24px;
  overflow: hidden;
}

.button-app .button .checkbox:checked+.knobs:before {
  content: "";
  left: 18px;
  background-color: #198754;
}

.button .checkbox:checked~.layer {
  background-color: #98e5c2;
}

.button-app .button .knobs:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 4px;
  width: 18px;
  height: 18px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: #dc3545;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  width: 74px;
  height: 36px;
  overflow: hidden;
}

.layer {
  background-color: #ffb1b1;
}

.button.r,
.button.r .layer {
  border-radius: 100px;
}

.button.b2 {
  border-radius: 2px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: #575A74;
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.nav-tabs .nav-link {
  border: unset;
  background-color: unset;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #2281E3;
  border-bottom: 2px solid #2281E3;
  background-color: unset;
}

.nav-link:focus,
.nav-link:hover {
  color: #575A74;
}

.form-switch .form-check-input {
  width: 3em;
}

.lds-ring {
  display: inline-block;
  position: absolute;
  width: 50px;
  height: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  margin: 0;
  border: 2px solid #6c757d;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #6c757d transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #c2dbfe;
  outline: 0;
  box-shadow: 0 0 0 1px rgba(13, 110, 253, .25);
}

.form-select:focus {
  border-color: #a1a7ab;
  outline: 0;
  box-shadow: 0 0 0 0.05rem rgb(227 229 231);
}

.form-check-input:focus {
  border-color: #a1a7ab;
  outline: 0;
  box-shadow: 0 0 0 0.05rem rgba(227 229 231);
}

.form-group select {
  width: 100%;
  border: 1px solid #1111111A;
  border-radius: 8px;
  padding: 16px 16px 13px;
  color: #575A74;
  font-size: 14px;
  outline: none;
  text-transform: capitalize;
}

.required-lable {
  color: #d93838;
  font-weight: 400;
  font-size: 13px;
  font-style: italic;
  text-transform: capitalize;
}

.not-found-data {
  height: 50px;
  line-height: 50px;
  font-size: 22px;
}

label {
  margin-bottom: 5px;
}

.form-check-input:checked {
  background-color: #6c757d;
  border-color: #a1a7ab;
}

.form-check-input {
  border: 1px solid #9fa5aa;
}

.data-loader {
  height: 60px;
  line-height: 60px;
  font-size: 30px;
  font-weight: 700;
  color: darkgrey;
  text-align: center;
}

/*Checkboxes styles*/
.permission input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]+label {
  display: block;
  position: relative;
  padding-left: 25px;
  color: var(--theme-text);
  cursor: pointer;
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

input[type="checkbox"]+label:last-child {
  margin-bottom: 0;
}

input[type="checkbox"]+label:before {
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  border: 2px solid var(--theme-bg);
  position: absolute;
  left: 0px;
  top: 3px;
  transition: all .12s, .08s;
}

input[type="checkbox"]:checked+label:before {
  width: 10px;
  top: 0;
  left: 5px;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  transform: rotate(39deg);
}

/* -------------- Pagenation ------------- */

.footer-pagination {
  padding-top: 17px;
}

.pages-item.active {
  background-color: #2281e4;
  border-radius: 8px;
  color: #fff;
}

.footer-pagination .pages-item {
  padding: 6px 14px;
  cursor: pointer;
}

.footer-pagination .pages-item:not(.active):hover {
  background: #2281e445;
  border-radius: 8px;
}

/* ---- End ---- Pagenation ---- End ---- */

/* ------ PageNotFound ---------- */

.page-not-found {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section {
  text-align: center;
  height: calc(100vh - 129px);
}

.section a {
  text-decoration: none;
}

.section .error {
  font-size: 150px;
  color: var(--theme-text);
  text-shadow:
    1px 1px 1px #575a74,
    2px 2px 1px #575a74,
    3px 3px 1px #575a74,
    4px 4px 1px #575a74,
    5px 5px 1px #575a74,
    6px 6px 1px #575a74,
    7px 7px 1px #575a74,
    8px 8px 1px #575a74,
    25px 25px 8px rgba(0, 0, 0, 0.2);
}

.page {
  margin: 2rem 0;
  font-size: 20px;
  font-weight: 400;
  color: var(--theme-text);
}

.back-home {
  display: inline-block;
  border: 2px solid var(--theme-text);
  color: var(--theme-text);
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1rem 0.6rem;
  transition: all 0.2s linear;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
}

.back-home:hover {
  background: var(--theme-text);
  color: #575A74;
}

/* ----- End ------ PageNotFound --------- End ------------ */

/* --- Sidebar section CSS --- */

.sidebar {
  width: 256px;
  height: 100%;
  background-color: var(--theme-bg);
  position: fixed;
  top: 0;
}

.brand-link {
  font-size: 24px;
  line-height: 80px;
  font-weight: 700;
}

.brand-link a {
  text-decoration: none;
  color: #FFFFFF;
  font-weight: 400;
  display: block;
  margin: auto;
  text-align: center;
}

.sidemenus .menu-title {
  margin-left: 24px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
}

.sidemenus hr {
  margin: 40px 24px;
  color: #ffffffb0;
}

.sidemenus .menu-item {
  margin-left: 12px;
  color: #fff;
}

.sidemenus a.menuitem.active:hover .menu-item.active,
.sidemenus .menu-item.active {
  color: #57aaff;
}

.sidebar .sidemenu {
  margin-left: 32px;
  margin-right: 32px;
}
a.menuitem:hover svg path,
a.menuitem:hover .menu-item{
  stroke: #a9a9a9;
  color:#a9a9a9;
}
.sidemenu .menuitem {
  height: 45px;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 5px 0px;
  border-radius: 7px;
  text-decoration: none;
}

.menuitem .menu-icon {
  flex: 10%;
  text-align: center;
}

.menuitem .menu-title {
  flex: 70%;
  color: #b5b5b5;
  font-size: 17px;
  text-transform: capitalize;
}

.menu-item.active {
  color: #2281E3;
}

/* ----- End ------ Sidebar section CSS ------- End --------- */

/* ---- Header section CSS --- */

.main-header {
  background-color: #ffffff;
  margin-left: 256px;
  position: sticky;
  width: calc(100% - 256px);
  top: 0;
  z-index: 999;
  display: flex;
  height: 80px;
  padding: 0 24px;
  justify-content: space-between;
  box-shadow: 0px -8px 29px 0px rgb(143 143 143 / 43%);
}

.nav-bar .nav-head {
  line-height: 80px;
  font-size: 20px;
  font-weight: 700;
  color: var(--theme-text);
  text-transform: capitalize;
}

.user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-info {
  padding-left: 10px;
  font-size: 18px;
  text-transform: capitalize;
}

.user-info a {
  text-decoration: none;
  color: #575A74;
  transition: .2s;
}

.user-panal {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50px;
  overflow: hidden;
  position: relative;
}

.user-avatar .user-name-avatar {
  position: absolute;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -48%);
  font-size: 27px;
  text-transform: capitalize;
}

.nav-bar {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 35px;
}

.nav-bar .nav-item {
  padding: 10px;
}

.main-header .nav-bar .nav-item a {
  color: #8f8f8f;
}

.main-header .nav-bar .nav-item a:hover {
  color: #464646;
}

.main-header .nav-bar .user-profile {
  position: relative;
}

.profile-menu {
  position: absolute;
  width: 200px;
  top: 60px;
  background: #f8f8f8;
  border: 1px solid #1111111a;
  border-radius: 8px;
  right: 0;
  display: none;
}

/* .profile-menu ul li:hover {
  background-color: #1111111a;
} */

.profile-menu-title {
  padding: 15px 14px;
  color: #575a74;
  border-bottom: 1px solid #1111111a;
  font-size: 17px;
}

.profile-menu .menuitem {
  width: 100%;
  display: flex;
  text-decoration: none;
  gap: 14px;
  padding: 17px 10px;
  border-bottom: 1px solid #1111111a;
}

.profile-menu .menu-item {
  color: #575A74;
}

/* ---- End ------- Header section CSS -------- END ------- */

/* ------- Wrapper section CSS ------- */

.content {
  padding: 24px;
  background: #fff;
  border-radius: 10px;
}

.content-header-right {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.content-header-right select {
  padding: 10px 20px 10px 10px;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  color: #757575;
  outline: none;
}

.content-header-btn {
  padding: 12px;
  border-radius: 8px;
  outline: none;
  border: none;
  color: #fff;
}

.content-header-search {
  position: relative;
}

.content-header-search input[type="search"] {
  background-color: #fff;
  border: 1px solid #E7E7E7;
  outline: none;
  border-radius: 10px;
  padding: 11px 16px 11px 40px;
  width: 260px;
}

.search-icon {
  position: absolute;
  top: 50%;
  z-index: 999;
  transform: translateY(-50%);
  left: 4%;
}

.content-wrapper {
  margin-left: 256px;
  background: var(--theme-content-bg);
  padding: 24px;
}

.content-wrapper .form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #6c757d8f;
  outline: 0;
  box-shadow: 0 0 0 1px rgb(108 117 125 / 19%);
}

.content-header {
  padding: 0px 0px 10px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.content-header h1 {
  font-size: 30px;
}

.content-header a {
  /* color: #fff; */
  text-decoration: none;
}

.content-box {
  padding: 24px 24px 16px;
  border-radius: 12px;
  position: relative;
  background-color: #ffffff;
}

.box-content {
  position: relative;
}

.content-box h1 {
  margin: 0;
  font-size: 32px;
  color: var(--theme-text);
}

.content-box hr {
  color: #11111169;
}

.content-box .box-icon {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 0;
  top: 0;
  background: #FFF4D5;
  text-align: center;
  line-height: 40px;
  border-radius: 4px;
}

.box-info {
  font-weight: 600;
  font-size: 16px;
  color: #575A74;
}

.content-box-btn {
  display: block;
  text-align: center;
  background: rgba(0, 0, 0, 0.151);
  border-radius: 5px;
  text-decoration: none;
  color: currentColor;
  padding: 3px;
  margin-top: 10px;
}

.box-footer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.box-footer p {
  font-size: 14px;
  color: #575A74;
}

.box-footer .counter {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 4px;
  padding: 4px;
}

.box-icon.customer {
  background-color: #EAF0FF;
}

.box-icon.profile {
  background-color: #F1F5F3;
}

.box-icon.expemes {
  background-color: #F2ECFF;
}

/* ----------------------------- */

/* ---- Content Form CSS ---- */

.content.content-form {
  background: #FFFFFF;
  padding: 8px 24px 24px 24px;
  border-radius: 10px;
}

.employee-box {
  border: 1px solid #d1d1d1;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.employee-info {
  width: calc(100% - 140px);
}

.employee-info hr {
  margin: 9.5px 0px;
}

.employee-name {
  font-size: 19px;
  font-weight: 600;
}

.employee-position {
  background-color: #010226;
  display: inline;
  padding: 2px 10px;
  font-size: 13px;
  border-radius: 50px;
  color: white;
  text-transform: capitalize;
}

.employee-box .employee-image {
  width: 130px;
  height: 130px;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
}

.employee-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.employee-image svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.employee-action {
  display: flex;
  justify-content: space-between;
}

.user-image {
  display: flex;
  align-items: center;
  gap: 22px;
  justify-content: center;
}

.user-image .preview-image {
  border-radius: 50%;
  width: 185px;
}

.user-image .user-preview-image {
  width: 185px;
  height: 185px;
  border-radius: 50%;
  position: relative;
  border: 1px dashed #e7e7e7;
  overflow: hidden;
}

.user-preview-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-image .user-text-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 75px;
  transform: translate(-50%, -50%);
  color: #fff;
}

.user-image .preview-image {
  border: 1px dashed #9f9f9f;
}

.preview-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview-image .app-text-logo {
  font-size: 60px;
  background: linear-gradient(135deg, rgba(115, 156, 255, 1) 0%, rgba(211, 107, 255, 1) 50%, rgba(230, 114, 118, 1) 100%);
  color: white;
  padding: 41px;
  border-radius: 12px;
  height: 100%;
  text-transform: capitalize;
}

.upload_app_text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.product-img-icon {
  position: absolute;
  right: 5%;
  bottom: 11px;
  width: 40px;
  height: 40px;
  background: #4d5154;
  border-radius: 50%;
  opacity: 0;
  transition: .3s;
}

.product-images:hover .product-img-icon {
  opacity: 1;
}

.appdetails-switch {
  display: flex;
  gap: 16px;
  align-items: center;
  border: 1px solid #1111111A;
  border-radius: 8px;
  padding: 14px 16px;
}

.appdetails-switch p {
  color: var(--theme-text);
  size: 16px;
}

.add-submit-top {
  position: absolute;
  right: 1.5%;
  bottom: 94%;
}

/* --------------------------- */

/* --- Footer section CSS --- */

.main-footer {
  margin-left: 265px;
  height: 55px;
  line-height: 55px;
  background-color: #f7f7f7;
  border-top: 1px solid;
  text-align: center;
  color: #8f8f8f;
  bottom: 0;
  position: sticky;
  width: calc(100% - 265px);
  z-index: 999;
}

.main-footer strong {
  font-size: 18px;
  color: #898989;
}

/* ------------------------- */

.modal-content {
  border-radius: 20px;
}

.modal-title {
  color: #010226;
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
  font-size: 18px;
  font-weight: 700;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0px 17px;
  border-bottom: 1px solid #1111111A;
  margin: 0px 24px;
}

.permission-model hr {
  margin: 7px 0px;
}

.permission-model .permission-box {
  display: flex;
  gap: 20px;
  margin-bottom: 24px;
}

.permission-model .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 24px 24px 0;
}

.permission-model input[type="checkbox"],
.type-add input[type='checkbox'] {
  display: none;
}

.permission-model .btn-close:focus {
  outline: 0;
  opacity: var(--bs-btn-close-focus-opacity);
}

.custome-title {
  position: relative;
}

.custome-title p {
  border: 1px solid #1111111A;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: var(--theme-text);
  padding: 12px 16px;
  font-size: 16px;
  border-radius: 8px;
  background-color: #ffffff;
  z-index: 9999;
}

.custome-title::after {
  content: "";
  position: absolute;
  background: #1111111A;
  width: 50%;
  height: 1px;
  top: 25px;
  margin-left: 10px;
}

.custome-title::before {
  content: "";
  position: absolute;
  background: #1111111A;
  width: 50%;
  height: 1px;
  top: 25px;
  right: 0;
}

.table-list .table-status {
  width: 145px;
  text-align: center;
}

.apps-table .table-status {
  width: 75px;
  text-align: center;
}

.table-list .table-action {
  width: 100px;
  text-align: center;
}

.table-list .table-image {
  width: 150px;
  text-align: center;
}

.table-list .table-more {
  position: relative;
}

.table-list .table-actions-menu {
  position: relative;
}

.table-list .table-action-menu {
  width: max-content;
  border: 1px solid #1111111A;
  border-radius: 10px;
  position: absolute;
  right: 65%;
  background-color: #FFFFFF;
  top: 0;
  overflow: hidden;
}

.action-btn {
  display: flex;
  gap: 7px;
  cursor: pointer;
  text-decoration: none;
}

.action-text {
  color: #575A74;
}

.p-component,
.p-component * {
  box-sizing: border-box;
}

.p-multiselect {
  width: 100%;
  display: inline-flex;
  user-select: none;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 6px;
}

.p-multiselect-label {
  display: block;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-multiselect .p-multiselect-label {
  padding: 0.75rem 0.75rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-multiselect-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
}

.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #6c757d;
  width: 3rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.p-multiselect-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-multiselect-panel {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.p-multiselect-panel .p-multiselect-items {
  padding: 0.75rem 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--theme-text);
}

.p-multiselect-panel .p-multiselect-header {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #dee2e6;
  color: #343a40;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-multiselect-close {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  margin-left: auto;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-checkbox {
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  vertical-align: bottom;
  position: relative;
}

.p-checkbox .p-checkbox-box {
  border: 2px solid #010226;
  background: #ffffff;
  width: 22px;
  height: 22px;
  color: #495057;
  border-radius: 6px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #010226;
  background: var(--theme-bg);
}

.p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon {
  width: 14px;
  height: 14px;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}

svg.p-icon {
  pointer-events: auto;
}

.p-icon {
  display: inline-block;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.75rem 1.25rem;
  border: 0 none;
  color: #495057;
  background: transparent;
  transition: box-shadow 0.2s;
  border-radius: 0;
}

.p-multiselect-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: normal;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.p-component span {
  margin-left: 10px;
}

.permission-box {
  display: flex;
  gap: 20px;
  margin-bottom: 24px;
}

.permission-box input[type="checkbox"] {
  display: none;
}

.permission-title {
  font-size: 18px;
  margin-bottom: 12px;
  font-weight: 500;
}
.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input {
  padding-right: 30px;  
  width: 100%;
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}
.form-group textarea.inputError,
.form-group input.inputError{
  border-color: red;
}
.svg-wrap svg{
  height: 18px;
  width: 18px;
}
.pagination__item,
.pagination__next {
  align-items: center;
  border: 1px solid #e4e4e4;
  border-radius: 12px;
  color: #11142d;
  display: flex;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  justify-content: center;
  margin: 8px 0 0 8px;
  transition: all .2s;
  width: 40px;
}
button.pagination__next:disabled,
button.pagination__next[disabled] {
  border-color: #e4e4e4;
  color: #e4e4e4;
}
button.pagination__next:disabled svg path{
  stroke: #e4e4e4;
  fill: #e4e4e4;
}
.pagination__next.leftarw {
  transform: rotate(180deg);
}
.pagination__item.active {
  background: var(--theme-primary);  
  border-color: var(--theme-primary);
  color: #fff;
  
}

.error{
  color:red;
}
.sidemenus .menuitem svg path{
  stroke: #fff;
}
.sidemenus .menuitem.active svg path{
  stroke: #57aaff;
}
.employee-model .modal-dialog{
  max-width: 95%;
}
.empname{
    position: absolute;
    right: 30px;
  }
.final-date{
    position: absolute;
    right: 0;
    top: -50px;
  }
.taskmodal .modal-dialog .modal-body{
  max-height: 600px;
  overflow-y: scroll;
}
.whitecal svg path{
  stroke: #fff;
}
.userwrapkk ,.cardsprint{  
  width: calc(25% - 18px);
}
.cardsprint {
  overflow: hidden;
  border: 0;
  border-radius: 15px;
  padding: 15px 8px;
  position: relative;
  background: #f4f4f4;  
}
.cardsprint h5{
  color:#999
}
.cardsprint.inprogress{
  background-color: #f4daff;
}
.cardsprint.complete {
  background-color: #e5f5b8;
}
.cardsprint.complete h5 {
  color: #50740e
}
.cardsprint.overdue {
  background-color: #f39b9b;
}

.cardsprint.overdue h5 {
  color: #a3091d
}
.cardsprint.inprogress h5{
  color: #8b4492
}
.scard{
    background: #fff;
    border-radius: 12px;
    padding: 15px;
    min-height: 300px;
  }
.calsvg svg path{
  stroke:#fff;
}
.form-group .css-1fdsijx-ValueContainer {
  padding: 0px 8px;
}
.form-group .css-qbdosj-Input {
  margin: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}
.form-group .css-13cymwt-control{
  border-color:#1111111A;  
}
.form-group .css-1u9des2-indicatorSeparator {
  background-color:#1111111A;
}
.form-group .css-1xc3v61-indicatorContainer {  
  color: #000;
}
.uuu .MuiPaper-root{
  left:auto !important;
  right:25px !important;
  top: 70px !important;
}
.profilesss .dropdown-menu.show {
  display: block;
  transform: translate(-70px, 26px) !important;
}
.dash-bg{
    background: #DEDFFF;
    padding: 20px;
    border-radius: 15px;
        width: calc(25% - 18px);
  }
   .dash-image {
      width: 60px;
      height: 60px;
      background: #000;
      border-radius: 12px;
      line-height: 60px;
      text-align: center;
    }
    .dash-bg.pbg{
      background-color: #FFD9D9;
    }
    .bg{
      background-color: #E5ECF6;      
    }
    .bgg{
      background-color:#E5ECF6 ;
    }
       .imagewrapdsh {
          width: 40px;
          height: 40px;
          position: relative;
          border-radius: 50%;
          overflow: hidden;
        }
                .imagewrapdsh img{
                  position: absolute;
                  width: 100%;
                  top:50%;
                  left: 50%;
                  transform: translate(-50%,-50%);
                }
                .marginrigth{
                  margin-right: 6px;
                }
.empdtl{
  width: calc(33.33% - 18px);
  padding: 15px;
  background-color: #F0F7FD;
  border-radius: 12px;
}
.empdtl .table td,.empdtl .table th{
  background-color: transparent;
}
.sprintgap .col-6{
  width: calc(50% - 8px);
}
.spgap .form-group{
  margin-top: 0;
}
.sprint-modal .modal-dialog{
  max-width: 75%;
}
.table-wrap{
  max-height: 600px;  
  overflow: hidden;
}
.marginleft{
  margin-left: 15px;
}