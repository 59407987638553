.project-profiles {
    display: flex;
    align-items: center;
}

.project-avatar {
    width: 50px;
    height: 50px;
    display: block;
    border-radius: 50px;
    overflow: hidden;
}

.project-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.project-profiles .all-profile {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
}

.team {
    width: 100%;
    min-height: 50px;
    padding: 10px;
    background: #e9e9e9;
    border: 1px solid #8f8f8f;
    border-radius: 8px;
    margin: 5px 0px 24px 0px;
    position: relative;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.team .team-member {
    width: max-content;
    background: #a7a7a7;
    padding: 5px;
    font-weight: 500;
    border-radius: 5px;
}

.menu-nav {
    background-color: #44B;
    display: flex;
    justify-content: space-between;
}

.menu-item {
    color: #000;
    padding: 3px;
}

.three-dots:after {
    cursor: pointer;
    color: #000;
    content: '\2807';
    font-size: 20px;
    padding: 0 5px;
}

a {
    text-decoration: none;
    color: white;
}
.dropdown1 {
    position: absolute;
    right: 10px;  
    background-color: #fff;
    box-shadow: -1px 1px 2px 1px #000;
    outline: none;
    max-height: 0;   
    padding: 5px 10px;
    min-width: 120px;
    display: none;
}
.dropdown1 button{
    width: 100%;
    border :0;
    background-color: #fff;
}
.dropdown1 button:hover {
color:var(--theme-primary);
}
.dropdown-container:focus {
    outline: none;
}

.dropdown-container:focus .dropdown1 {
    /* opacity: 1; */
    display: block;
    z-index: 100;
    max-height: 100vh;
}
.dotbtn.show,.dotbtn:focus,.dotbtn.show:hover,.dotbtn:hover,.dotbtn:first-child:active{
    background: transparent;
}
button.dotbtn{
        padding: 0;
        border: 0;
        background: transparent;
    }
        button.dotbtn::after {display: none;}