.react-datepicker__input-container .react-datepicker__calendar-icon {
    position: absolute;
    padding: 11px 12px;
    box-sizing: content-box;
}
.datttepicker .react-datepicker__input-container .react-datepicker__calendar-icon{
    padding: 15px 12px;
}
.form-group .react-datepicker-wrapper input[type="text"]{
    padding: 0 10px 0 40px;
}
.react-datepicker__view-calendar-icon input {
    padding: 6px 10px 5px 40px;
    border: 1px solid #1111111A;
    background-color: #f8f8f8;
    border-radius: 8px;
}

.react-datepicker {
    font-family: unset;
    font-size: 0.7rem;
    background-color: #fff;
    color: #010226;
    border: 1px solid #e7e7e7;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
}

.react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #e7e7e7;
    border-top-left-radius: 0.3rem;
    padding: 8px 0;
    position: relative;
}

.react-datepicker__triangle::after {
    border: unset;
}

.user-profile .profile-head {
    font-size: 16px;
    font-weight: 500;
    padding: 12px 0px 0px;
}

.profile-info {
    display: flex;
    gap: 20px;
    position: relative;
    align-items: center;
    padding: 24px 0px;
    border-bottom: 1px solid #1111111A;
}

.user-profile .profile-user-avatar {
    width: 100px;
    height: 100px;
    overflow: hidden;
    border: 1px dashed #dadada;
    border-radius: 50%;
    position: relative;
    background-color: #F7F7F7;
}

.profile-user-avatar .pre-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.profile-info .profile-user-info {
    font-size: 16px;
    color: #010226;
}

.profile-user-info p:first-child {
    font-size: 20px;
}

.profile-user-info .profile-mail {
    font-size: 17px;
    font-weight: 400;
    color: #2281E3;
}

.profile-user-info .user-position {
    font-size: 14px;
    background: #565973;
    border-radius: 10px;
    padding: 5px 10px;
    color: #fff;
    margin-top: 10px;
    display: inline-block;
}

.profile-user-info .profile-btn {
    font-size: 12px;
    border-radius: 6px;
    padding: 2px 7px;
    background: #2281e4;
    outline: none;
    border: none;
    color: white;
    margin-top: 5px;
    cursor: pointer;
}

.profile-user-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.attendance-head {
    padding: 0px 0px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #1111111A;
    flex-wrap: wrap;
}
.content.user-profile{
    padding: 0;
}
.th-date {
    width: 200px;
}

.th-festival {
    width: 400px;
}
.search-date {
    display: flex;
}

.check-in-btn {
    padding: 12px;
    background: #0EB17F;
    border: none;
    margin-right: 16px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
}
.check-in-btn.lunch:disabled,
.check-in-btn.wh:disabled,
.check-in-btn.tea:disabled,.check-out-btn.out:disabled{
    opacity: 0.5;
    cursor: not-allowed;
    display: none;
}
.check-in-btn.tea{
    background: cadetblue;
}
.check-in-btn.lunch {
    background-color: chocolate;
}
.check-out-btn {
    padding: 12px;
    background: #D2543C;
    border: none;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px;
}

.attend-filed {
    width: 160px;
}