.table-list .app-logo {
    width: 65px;
    height: 65px;
    border-radius: 8px;
    overflow: hidden;
    margin: auto;
}

.table-list .app-logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.table-list .app-text-logo {
    width: 65px;
    height: 65px;
    margin: auto;
    background: linear-gradient(135deg, rgba(115, 156, 255, 1) 0%, rgba(211, 107, 255, 1) 50%, rgba(230, 114, 118, 1) 100%);
    color: rgb(255, 255, 255);
    font-size: 36px;
    border: 2px solid;
    border-radius: 8px;
    text-align: center;
    line-height: 65px;
    box-sizing: content-box;
    text-transform: capitalize;
}

.image-uploader {
    background: #F7F7F7;
    padding: 16px;
    border-radius: 8px;
}

.preview-image {
    border: 1px dashed #1111111A;
    text-align: center;
    background: #FFFFFF;
    border-radius: 12px;
    overflow: hidden;
}

.monetize-switch {
    display: flex;
    gap: 16px;
    justify-content: center;
    border: 1px solid #1111111A;
    border-radius: 8px;
    padding: 14px 0px;
    margin-bottom: 24px;
}

.monetize-switch p {
    size: 16px;
}

.ad-setting {
    border: 1px solid #1111111A;
    border-radius: 10px;
}

.ad-setting hr {
    margin: 0;
    color: #959595;
}

.tab-title {
    padding: 24px 0px 0px;
    color: var(--theme-text);
    font-size: 16px;
}

.add-submit {
    text-align: end;
}

.select-app-type {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
}

.select-app-type .app-type {
    cursor: pointer;
    color: #010226;
    font-size: 16px;
    background-color: white;
    display: flex;
    align-items: center;
    gap: 15px;
    border-radius: 10px;
    padding: 10px;
    border: 1px dashed #d9d9d9;
}

/* .select-app-type .app-type:first-child {
    padding-left: 0px;
}

.select-app-type .app-type:last-child {
    padding-right: 0px;
} */

.select-app-type .app-type p {
    font-size: 20px;
    color: #010226;
}

.app-icon {
    padding: 10px;
    background: #4a59ec45;
    border-radius: 10px;
}

.container {
    margin: auto;
    width: -moz-fit-content;
    width: fit-content;
}

div.ball {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 20px;
    display: inline-block;
    position: relative;
    animation: jump 1s infinite;
}

div.ball1 {
    background-color: #2281E3;
}

div.ball2 {
    background-color: #2281E3;
    animation-delay: 0.15s;
}

div.ball3 {
    background-color: #2281E3;
    animation-delay: 0.3s;
}

div.ball4 {
    background-color: #2281E3;
    animation-delay: 0.45s;
}

div.ball5 {
    background-color: #2281E3;
    animation-delay: 0.6s;
    margin-right: 20px;
}

@keyframes jump {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0);
    }
}