.calendar-container {
  width: 50%;
}
.calendar-container .react-calendar {
    width: 80%;
    margin: auto;
    padding: 25px;
}
.calendar-container .react-calendar__tile {    
    padding: 20px 30px;
    font-size: 20px;
}
.react-calendar__tile.holiday {
    background-color: red;
    color: white;
    border-radius:50%
}
.calendar-container .react-calendar__month-view__weekdays ,
.calendar-container .react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from,
.calendar-container .react-calendar__navigation button{   
    font-size: 25px;   
    text-decoration: none;
}
.calendar-container .react-calendar__month-view__weekdays__weekday abbr{
    text-decoration: none;
}
.sunday {
    color: red;
}
.react-calendar__month-view__days__day--weekend.saturday{
    color:inherit
}

.reason-table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}

.reason-table th,
.reason-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.reason-table th {
    background-color: #f2f2f2;
}

.error {
    color: red;
}

.submit-button {
    margin-top: 20px;
}

.delete-button {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
}
.calendar-container .react-calendar__tile--now:enabled:hover,
.calendar-container .react-calendar__tile--now:enabled:focus,
.calendar-container .react-calendar__tile--now{
    background-color: #1087ff;
    color:#fff
}
